import React from 'react'
import Hero from './Hero'
import Categories from './Categories'

const Home = () => {
  return (
    <>
   <Hero/>
   <Categories/>
    </>
  )
}

export default Home